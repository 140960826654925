<template>
  <div class="recommend-page">
    <div class="page-row">
      <div class="rule-btn" @click="ruleVisible = true">
        <span>活动规则</span>
      </div>
      <img
      src="//download-cos.yofish.com/xz-gongjushiyebu/20221027103958844-recommend-bg.png" alt="" />
      <div class="qrcode-box">
        <div class="qrcode" @click="dowloadImage">
          <img :src="qrcodeUrl" alt="">
        </div>
        <p class="desc">(扫一扫添加客服微信)</p>
      </div>
    </div>
    <y-actionsheet :is-visible="ruleVisible"
      :isLockBgScroll="true"
      @close="closeActionsheet">
      <span slot="sTitle">活动规则</span>
      <div slot="custom">
        <rule />
      </div>
    </y-actionsheet>
  </div>
</template>
<script>
import rule from './rule';
import YActionsheet from '@/components/actionsheet';

export default {
  name: 'Recommend',
  components: { rule, YActionsheet },
  data() {
    return {
      ruleVisible: false,
      qrcodeUrl: 'http://download-cos.yofish.com/xz-gongjushiyebu/20221027155740572-qrcode.png',
    };
  },
  mounted() {
    document.title = '种草赢会员';
  },
  methods: {
    closeActionsheet() {
      this.ruleVisible = false;
    },
    dowloadImage() {
      window?.android?.saveWebImg(this.qrcodeUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.recommend-page {
  width: 100%;
  .page-row {
    width: 750px;
    margin: auto;
    position: relative;
    > img {
      width: 100%;
    }
  }
  .rule-btn {
    width: 53px;
    height: 151px;
    background: rgba(163,250,200,0.6);
    border-radius: 10px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 228px;
    cursor: pointer;
    span {
      width: 22px;
      font-size: 23px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #206640;
    }
  }
  /deep/ .nut-actionsheet-title {
    position: absolute;
    width: 100%;
    height: 80px;
    left: 0;
    line-height: 80px;
    text-align: center;
    span {
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 95px;
    }
  }
  /deep/ .nut-actionsheet-panel {
    border-radius: 22px 22px 0 0;
    background-color: #fff;
    overflow: hidden;
    // position: absolute;
    height: 90%;
  }
  /deep/ .nut-actionsheet-panel {
    min-height: 50%;
    .nut-actionsheet-custom {
      margin: 80px 0px 0 26px;
      position: relative;
      overflow: auto;
      padding-right: 26px;
      height: calc(100% - 80px);
      // height: 100%;
      // overflow-y: auto;
    }
  }
  .qrcode-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 48px;
    text-align: center;
    .qrcode {
      width: 152px;
      margin-bottom: 32px;
      margin-left: auto;
      margin-right: auto;
    }
    > p {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #206640;
    }
    img {
      width: 100%;
    }
  }
}
</style>
