<template>
  <div>
    <p>1、活动时间：2022年11月1日——2022年11月30日</p>
    <p>2、活动规则：活动期间发小红书写下使用小账管家APP的使用感受不少于100字，至少配图3张，文章保留1个月，不得删除，即可获得小账管家会员。</p>
    <p>
      <span style="color: rgb(227, 55, 55);">注：每个设备号用户只能参与1次</span>
    </p>
    <p>3、活动奖励：</p>
    <p>用户参与活动分享达到5个赞——可获得14天试用会员</p>
    <p>用户参与活动分享到达20个赞——可获得月卡会员</p>
    <p>用户参与活动分享达到188个赞——可获得年卡会员</p>
    <p>
      <span style="color: rgb(227, 55, 55);">注：兑换截至日期：2022年12月1日</span>
    </p>
    <p>3、领取方式：<span style="font-size: 16px;">达到要求后添加微信客服，将参与手机号码，
      小红书链接和点赞截图发给客服，客服会在3个工作日内审核，通过后会将相应奖励发放至您所参与的手机号码当中。</span>
    </p>
    <p>
      <span style="font-size: 16px;">
        <img alt="Image" :src="imageUrl" @click="dowloadImage"
          width="100" height="100">
        <br>
      </span>
    </p>
    <p>
      <span style="color: rgb(227, 55, 55); font-size: 16px;">注：如经过排查发现互刷行为将取消您的参与资格，
        本次活动解释权归小账管家所有</span>
      <br>
    </p>
  </div>
</template>
<script>
export default {
  name: 'Rule',
  data() {
    return {
      imageUrl: 'http://download-cos.yofish.com/youyu-gongjushiyebu/20221026175906600-xiaozhang.jpg',
    };
  },
  methods: {
    dowloadImage() {
      window?.android?.saveWebImg(this.imageUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
  p {
    font-size: 26px;
    line-height: 42px;
  }
</style>
